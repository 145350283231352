import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// core components
import Grid from "@material-ui/core/Grid"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import styles from "../../jss/landing-webdesign.js"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const getImages = graphql`
  {
    file(relativePath: { eq: "painting-contractor-website-design.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
const useStyles = makeStyles(styles)
export default function SectionCards() {
  const classes = useStyles()
  const data = useStaticQuery(getImages)
  return (
    <div
      className={classNames(classes.section, classes.sectionDark)}
      style={{ padding: "60px 0px 60px 0px" }}
    >
      <Grid container justify="center" alignItems="center" spacing={2}>
        <Grid item md={5} sm={5} xs={10} lg={4}>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="painting-contractor-website-design"
          />
        </Grid>
        <Grid item md={6} sm={8} lg={5} xs={11}>
          <h3 className={classes.title}>Launch with Confidence</h3>
          <h6 className={classes.description}>
            Painting Contractor Web Design
          </h6>
          <p className={classes.description}>
            SEO strategies go hand in hand with modern web design. If you don’t
            have a user-friendly website, you won’t get the needed conversion
            even if you are at the top of search results. So before you launch
            your new website, you must make sure that the web design can turn
            visitors into paying clients.
          </p>
          <p className={classes.description}>
            Atlas Agency only uses the latest techniques and hosting platforms
            as our team is proficient in modern design solutions. Our web
            designers can quickly provide your website with the makeover it
            deserves. Whether for plumbers, electricians, or painters, we launch
            every contractor website with confidence.
          </p>
        </Grid>
      </Grid>
    </div>
  )
}

import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import Grid from "@material-ui/core/Grid"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"

import styles from "../../jss/intro.js"

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
  },
  section2: {
    padding: "30px 0",
  },
})

export default function ProductSection() {
  const classes = useStyles()

  return (
    <div>
      <Grid container justify="center" style={{ padding: "60px 0px 60px 0px" }}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <h2 className={classes.title} style={{ color: "#273746" }}>
            WHY CHOOSE US?
          </h2>
          <p className={classes.description}>
            Most consumers search for a local business online. If your website
            isn’t user-friendly, you will lose the chance to turn online
            visitors into paying clients. That’s why great web design is equally
            important as a great SEO strategy.
          </p>
          <p className={classes.description}>
            At Atlas Agency, we ensure that our clients gain access to
            high-standard web design solutions. With the help of our dedicated
            team, your painting contractor website will quickly get the design
            it deserves.
          </p>
        </Grid>
      </Grid>
    </div>
  )
}

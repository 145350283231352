import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import Grid from "@material-ui/core/Grid"
import InfoArea from "../../components/InfoArea/InfoArea.js"

import styles from "../../jss/intro.js"
import SpellcheckIcon from "@material-ui/icons/Spellcheck"

import CodeIcon from "@material-ui/icons/Code"
import CreateIcon from "@material-ui/icons/Create"

const useStyles = makeStyles(styles)

export default function ProductSection() {
  const classes = useStyles()
  return (
    <div className={classes.section} style={{ padding: "60px 0px 60px 0px" }}>
      <Grid container justify="center">
        <Grid item xs={10} sm={12} md={8} lg={8} align="center">
          <h2 className={classes.title}>Painting Contractor Web Design</h2>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={11}
          align="center"
          style={{ padding: "20px 0px 0px 0px" }}
        >
          <p className={classes.description}>
            Trying to establish an online presence as a contractor is tough.
            Almost every construction company out there has invested in SEO &
            web design. If you are a painting contractor, you are probably
            facing tough online competition. Luckily, ranking the highest in
            search results is easy when you have the right tools.
          </p>
          <p className={classes.description}>
            With the help of our team, you can easily reach Google’s top results
            and gain access to modern web design solutions. Atlas Agency is an
            SEO & web design company from Toronto, experienced in helping
            painting contractors improve their online presence. We are proud to
            offer painting contractor website design services.
          </p>
        </Grid>
      </Grid>
      <div style={{ padding: "20px 0px 0px 0px" }}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Custom Made Websites"
              description="We provide resources that will make your website stand out from competitors. Our custom website approach provides painting contractors with professional websites."
              icon={CreateIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Hosting"
              description="We make sure that your website is available 24 hours a day, 7 days a week. We also help in securing your desired domain and connecting it to your site."
              icon={SpellcheckIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Mobile Optimization"
              description="We optimize your website for all types of desktop usage, making sure you reach more potential visitors "
              icon={CodeIcon}
              iconColor="info"
              vertical
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
